import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageIntro from "../components/pageIntro"
import { Content, Bound, Col, Row } from "../components/layouts"
import BlogPostListing from "../components/BlogPostListing"
import MenuReset from "../components/MenuReset"

const TagPage = props => (
  <Layout>
    <SEO title="Tags" />
    <MenuReset />

    <PageIntro>
      <h2>
        Tag:
        {props.pageContext.tag}
      </h2>
    </PageIntro>

    <Content>
      <Row>
        <Col>
          <Bound>
            {props.data.allMarkdownRemark.edges.map(({ node }) => (
              <BlogPostListing
                key={node.fields.slug}
                title={node.frontmatter.title}
                slug={node.fields.slug}
                description={node.excerpt}
              />
            ))}
          </Bound>
        </Col>
      </Row>
    </Content>
  </Layout>
)

export default TagPage

export const query = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tags
          }
          excerpt
        }
      }
    }
  }
`
